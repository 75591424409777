import { FeatureItem } from 'components/items/FeatureItem';
import { Content } from 'components/layout/Content';
import { Grid } from 'components/layout/Grid';

export const Features = () => (
  <Content marginTop="5rem">
    <Grid
      width="100%"
      gridGap="1rem"
      padding="1rem"
      borderRadius="eight"
      backgroundColor="accent"
      gridTemplateColumns={{ sm: '1fr', md: '1fr 1fr' }}
    >
      <FeatureItem
        alt="UI tool"
        title="UI tool"
        src="/images/features/ui_tool.png"
        feature="Select only the images you need, and don't get lost in your Download folder."
      />
      <FeatureItem
        alt="Bulk Download"
        title="Bulk Download"
        src="/images/features/download.png"
        feature="Select all pictures inside one or multiple pages and download selected images into one zip file."
      />
      <FeatureItem
        alt="Label"
        title="Image labels"
        src="/images/features/label.png"
        feature="Get organized! Assign labels to selected images, and don't get lost in your Download folder."
      />
      <FeatureItem
        alt="JSON file"
        title="Export to JSON"
        src="/images/features/json.png"
        feature="Provide your developers or CMS experts with assets in the form that they want!"
      />
      <FeatureItem
        alt="Google Drive"
        src="/images/features/drive.png"
        title="Google Drive Sync"
        feature="You're busy and don't have time to waste uploading images to Google Drive. That's where Imageasy comes in..."
      />
      <FeatureItem
        alt="Filter"
        src="/images/features/filter.png"
        title="Filter images"
        feature="Instantly filter images by type, size, or label - no more trying to find hidden gems in a sea of images."
      />
      <FeatureItem
        alt="Vault"
        src="/images/features/vault.png"
        title="ImageVault"
        feature="Store all of your images in one place. Use permanent links whenever you want, on any device. Forget broken links!"
      />
      <FeatureItem
        alt="History"
        src="/images/features/history.png"
        title="Collection History"
        feature="Image history keeps all the image collections you select online."
      />
    </Grid>
  </Content>
);
