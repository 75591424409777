import Image from 'next/image';
import { FC } from 'react';
import styled from 'styled-components';

import { Box } from 'components/layout/Box';
import { Flex } from 'components/layout/Flex';
import { Text } from 'components/text/Text';

interface Props {
  title: string;
  feature?: string;
  children?: React.ReactNode;
  alt?: string;
  src?: string;
}
export const FeatureItem: FC<Props> = ({
  title,
  feature,
  children,
  alt,
  src,
}) => (
  <Container borderRadius="eight">
    <Box
      width="40px"
      height="40px"
      paddingTop="7.5px"
      paddingLeft="7.5px"
      borderRadius="eight"
      marginBottom="1rem"
      backgroundColor="hover"
    >
      <Image
        alt={alt}
        width={25}
        height={25}
        objectFit="contain"
        src={src || ''}
      />
    </Box>
    <Text
      color="white"
      fontWeight="bold"
      marginBottom="0.5rem"
      fontSize="regular1125"
    >
      {title}
    </Text>
    <Text color="white" lineHeight="big" fontSize="regular1">
      {feature || children}
    </Text>
  </Container>
);

const Container = styled(Flex)`
  padding: 1rem;
  flex-direction: column;
  &:hover {
    background-color: ${p => p.theme.colors.hover};
  }
`;
