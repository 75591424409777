import { FC } from 'react';
import styled from 'styled-components';

import { Box } from 'components/layout/Box';
import { Content } from 'components/layout/Content';
import { Flex } from 'components/layout/Flex';
import { Section } from 'components/layout/Section';
import { Text } from 'components/text/Text';
import { Theme } from 'styles/theme';

interface Props {
  title: string;
  video: string;
  feature: string;
  reverse?: boolean;
  bg?: keyof Theme['colors'];
}

export const FeatureVideoItem: FC<Props> = ({
  title,
  video,
  feature,
  reverse,
  bg = 'transparent',
}) => (
  <Section padding="4rem 0" backgroundColor={bg}>
    <Content
      gap="2rem"
      flexDirection={{ _: 'column', md: reverse ? 'row-reverse' : 'row' }}
    >
      <Flex justifyContent="center" flexDirection="column">
        <Text fontWeight="bold" fontSize="hero25" marginBottom="1rem">
          {title}
        </Text>
        <Text lineHeight="big" fontSize="regular">
          {feature}
        </Text>
      </Flex>
      <Video width={{ _: '100%', md: '55%' }}>
        <source src={video} type="video/mp4" />
      </Video>
    </Content>
  </Section>
);

const Video = styled(Box).attrs({
  as: 'video',
  autoPlay: true,
  loop: true,
  muted: true,
  playsInline: true,
})`
  width: 100%;
  height: 100%;
  border: 3px solid #cccccc;
  border-radius: ${p => p.theme.radii.eight};
`;
