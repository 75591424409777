import type { NextPage } from 'next';

import { FeaturePreview } from 'components/sections/FeaturePreview';
import { Features } from 'components/sections/Features';
import { Hero } from 'components/sections/Hero';
import { Preview } from 'components/sections/Preview';
import { Pricing } from 'components/sections/Pricing';

const Home: NextPage = () => {
  return (
    <>
      <Hero />
      <Preview />
      <Features />
      <FeaturePreview />
      <Pricing />
    </>
  );
};

export default Home;
