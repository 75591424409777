import Image from 'next/image';

import { LinkButton } from 'components/buttons/LinkButton';
import { Content } from 'components/layout/Content';
import { Flex } from 'components/layout/Flex';
import { Text } from 'components/text/Text';

export const Hero = () => {
  return (
    <Content marginTop="64px">
      <Flex
        width="100%"
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="flex-start"
      >
        <Text
          marginTop="5rem"
          fontWeight="bold"
          fontSize={{ _: 'hero2', sm: 'hero25', md: 'hero3' }}
        >
          Save your time with imageasy
        </Text>
        <Text
          width="80%"
          marginTop="1.5rem"
          lineHeight="biggest"
          fontSize="regular1125"
          color="subHeadingText"
        >
          A browser extension for capturing images online. Download images in
          bulk or pick them one by one with our UI tool from any website.
        </Text>
        <Flex
          gap="2rem"
          width="100%"
          marginTop="2rem"
          alignItems="center"
          justifyContent="space-between"
          flexDirection={{ _: 'column', sm: 'row' }}
        >
          <LinkButton
            paddingX="2rem"
            passHref
            href="https://chrome.google.com/webstore/detail/kclnnjnnpdfepoglcnklnicdjjlogeki"
          >
            <Text fontSize="regular1125" color="white">
              <b>Download Imageasy</b> It&apos;s free
            </Text>
          </LinkButton>
          <Flex
            paddingX="1rem"
            paddingY="0.5rem"
            alignItems="center"
            width="fit-content"
            borderRadius="ten"
            border="2px solid #CCCCCC"
          >
            <Text color="text60" marginRight="1rem" fontSize="big15">
              Works with:
            </Text>
            <Image
              alt="Chrome Logo"
              width={35}
              height={35}
              objectFit="contain"
              src="/images/browsers/chrome.png"
            />
          </Flex>
        </Flex>
      </Flex>
    </Content>
  );
};
