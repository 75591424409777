import { FC } from 'react';

import { FeatureVideoItem } from 'components/items/FeatureVideoItem';

import { Title } from './Title';

export const FeaturePreview: FC = () => (
  <>
    <Title
      title="See imageasy in action"
      subtitleColor="subHeadingText"
      subtitle="Built with simplicity and usability in mind, imageasy is a simple way to collect images from any website."
    />
    <FeatureVideoItem
      title="Select images with our UI tool"
      feature="Feeling lost in the chaos of Your Downloads folder? We got U!  Select only needed images right from the website you're on with our UI tool."
      video="/videos/ui_tool.mp4"
    />
    <FeatureVideoItem
      reverse
      bg="bgLight"
      title="Bulk Download"
      feature="Select all images across all open tabs and download selected images into one zip file. But what about dynamic pages? We have you covered here as well! Grab all the images as you scroll through the page with ease."
      video="/videos/bulk_download.mp4"
    />
    {/* <FeatureVideoItem
      title="Assign Labels"
      feature="We all know the pain when you have to deal with a bunch of seemingly random files with random names, so we created Labels to help you organize."
      video="https://www.automa.site/videos/section1.mp4"
    /> */}
  </>
);
