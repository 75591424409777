import styled from 'styled-components';

import { Box } from 'components/layout/Box';
import { Content } from 'components/layout/Content';

export const Preview = () => (
  <Content marginTop="5rem">
    <Box
      width="100%"
      height="auto"
      overflow="hidden"
      borderRadius="ten"
      border="3px solid #CCCCCC"
    >
      <StyledImg alt="Extension preview" src="/images/sections/preview.png" />
    </Box>
  </Content>
);

const StyledImg = styled.img`
  max-width: 100%;
  height: auto;
`;
